// THIS FILE WAS AUTOGENERATED
module Microsoft.Telemetry
{
"use strict";
    
    /**
     * The abstract common base of all domains.
     */
    export class Domain
    {
        
        constructor()
        {
        }
    }
}
