// THIS FILE WAS AUTOGENERATED
module AI
{
"use strict";
    
    /**
     * Type of the metric data measurement.
     */
    export enum DataPointType
    {
        Measurement = 0,
        Aggregation = 1,
    }
}
