// THIS FILE WAS AUTOGENERATED
/// <reference path="PageViewData.ts" />
module AI
{
"use strict";
    
    /**
     * An instance of PageViewPerf represents: a page view with no performance data, a page view with performance data, or just the performance data of an earlier page request.
     */
    export class PageViewPerfData extends AI.PageViewData
    {
        
        /**
         * Schema version
         */
        public ver: number;
        
        /**
         * Request URL with all query string parameters
         */
        public url: string;
        
        /**
         * Performance total in TimeSpan 'G' (general long) format: d:hh:mm:ss.fffffff
         */
        public perfTotal: string;
        
        /**
         * Event name. Keep it low cardinality to allow proper grouping and useful metrics.
         */
        public name: string;
        
        /**
         * Request duration in format: DD.HH:MM:SS.MMMMMM. For a page view (PageViewData), this is the duration. For a page view with performance information (PageViewPerfData), this is the page load time. Must be less than 1000 days.
         */
        public duration: string;
        
        /**
         * Network connection time in TimeSpan 'G' (general long) format: d:hh:mm:ss.fffffff
         */
        public networkConnect: string;
        
        /**
         * Sent request time in TimeSpan 'G' (general long) format: d:hh:mm:ss.fffffff
         */
        public sentRequest: string;
        
        /**
         * Received response time in TimeSpan 'G' (general long) format: d:hh:mm:ss.fffffff
         */
        public receivedResponse: string;
        
        /**
         * DOM processing time in TimeSpan 'G' (general long) format: d:hh:mm:ss.fffffff
         */
        public domProcessing: string;
        
        /**
         * Collection of custom properties.
         */
        public properties: any;
        
        /**
         * Collection of custom measurements.
         */
        public measurements: any;
        
        constructor()
        {
            super();
            
            this.ver = 2;
            this.properties = {};
            this.measurements = {};
        }
    }
}
